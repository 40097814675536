.table-wrapper {
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

.table-title {
	color: #fff;
	background: #4b5366;
	border-radius: 3px 3px 0 0;
}


.iconActive::before {
	font-family: "Font Awesome 5 Free"; font-weight: 400; content: "\f058";
}

.iconActiveSel::before {
	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f058";
	color: orange;
}

.iconStar::before {
	font-family: "Font Awesome 5 Free"; font-weight: 400; content: "\f005";
}

.iconStarSel::before {
	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f005";
	color: orange;
}

.iconVoice::before {
	font-family: "Font Awesome 5 Free"; font-weight: 400;
}

.iconVoiceSel::before {
	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f130";
	color: orange;
}
.icon {
	margin-right: 0.75em;
}

.text-red[data-value="on"] {
	color: #F44336 }
  
.text-pink[data-value="on"] {
	color: #E91E63 }
  
.text-purple[data-value="on"] {
	color: #9C27B0 }
  
.text-deep-purple[data-value="on"] {
	color: #673AB7 }
  
.text-indigo[data-value="on"] {
	color: #3F51B5 }
  
.text-blue[data-value="on"] {
	color: #2196F3 }
  
.text-light-blue[data-value="on"] {
	color: #03A9F4 }
  
.text-cyan[data-value="on"] {
	color: #00BCD4 }
  
.text-teal[data-value="on"] {
	color: #009688 }
  
.text-green[data-value="on"] {
	color: #4CAF50 }
  
.text-light-green[data-value="on"] {
	color: #8BC34A }
  
.text-lime[data-value="on"] {
	color: #CDDC39 }
  
.text-yellow[data-value="on"] {
	color: #ffe821 }
  
.text-amber[data-value="on"] {
	color: #FFC107 }
  
.text-orange[data-value="on"] {
	color: #FF9800 }
  
.text-deep-orange[data-value="on"] {
	color: #FF5722 }
  
.text-brown[data-value="on"] {
	color: #795548 }
  
.text-grey[data-value="on"] {
	color: #9E9E9E }
  
.text-blue-grey[data-value="on"] {
	color: #607D8B }
  
.text-black[data-value="on"] {
	color: #000000 }
  
.text-white[data-value="on"] {
	color: #ffffff }